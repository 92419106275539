import { useAccount, useClient, useWriteContract } from 'wagmi'
import { useCallback } from 'react'
import { BLOCK_CONFIRMATIONS, EMPTY_BYTES } from '@/constants'
import { FlowRate } from '@/types'
import { getFlowRatePerSecond } from '@/utils'
import { Address, Hash } from 'viem'
import { readContract, waitForTransactionReceipt } from 'viem/actions'
import { ChainId } from '@/config/networks'
import { cfAv1ForwarderABI, cfAv1ForwarderAddress } from 'evm-contracts'

export const useStreamActions = () => {
  const { address: sender, chainId } = useAccount()
  const client = useClient()

  const { writeContractAsync } = useWriteContract()

  const flowExists = useCallback(
    async (superToken: Address, receiver: Address) => {
      if (!sender || !client) return 0n

      const [_, flowrate] = await readContract(client, {
        address: cfAv1ForwarderAddress[chainId as ChainId],
        abi: cfAv1ForwarderABI,
        functionName: 'getFlowInfo',
        args: [superToken, sender, receiver]
      })

      return flowrate
    },

    [sender, client, chainId]
  )

  const createFlow = useCallback(
    async (
      superToken: Address,
      receiver: Address,
      flowRate: FlowRate,
      userData: Hash = EMPTY_BYTES
    ) => {
      if (!sender) return EMPTY_BYTES

      const fps = getFlowRatePerSecond(flowRate)

      return writeContractAsync({
        address: cfAv1ForwarderAddress[chainId as ChainId],
        abi: cfAv1ForwarderABI,
        functionName: 'createFlow',
        args: [superToken, sender, receiver, fps, userData]
      })
    },
    [sender, writeContractAsync, chainId]
  )

  const updateFlow = useCallback(
    async (
      superToken: Address,
      receiver: Address,
      flowRate: FlowRate,
      userData: Hash = EMPTY_BYTES
    ) => {
      if (!sender) return EMPTY_BYTES

      const fps = getFlowRatePerSecond(flowRate)

      return writeContractAsync({
        address: cfAv1ForwarderAddress[chainId as ChainId],
        abi: cfAv1ForwarderABI,
        functionName: 'updateFlow',
        args: [superToken, sender, receiver, fps, userData]
      })
    },
    [sender, writeContractAsync, chainId]
  )

  const deleteFlow = useCallback(
    async (superToken: Address, receiver: Address) => {
      if (!sender || !client) return

      const hash = await writeContractAsync({
        address: cfAv1ForwarderAddress[chainId as ChainId],
        abi: cfAv1ForwarderABI,
        functionName: 'deleteFlow',
        args: [superToken, sender, receiver, EMPTY_BYTES]
      })

      const rc = await waitForTransactionReceipt(client, {
        hash,
        confirmations: BLOCK_CONFIRMATIONS
      })

      return rc
    },
    [sender, client, writeContractAsync, chainId]
  )

  return {
    createFlow,
    updateFlow,
    deleteFlow,
    flowExists
  }
}
