import { useChainId, useClient, useWriteContract } from 'wagmi'
import { ChainId } from '@/config/networks'
import { useCallback, useEffect, useState } from 'react'
import { FlowRate, Torex } from '@/types'
import {
  sb712MacroAddress,
  sb712MacroABI,
  macroForwarderABI,
  macroForwarderAddress
} from 'evm-contracts'
import {
  Address,
  encodeAbiParameters,
  formatEther,
  parseAbiParameters,
  toHex,
  UserRejectedRequestError,
  zeroAddress
} from 'viem'
import usePrevious from 'react-use/lib/usePrevious'
import { useStreamActions } from './useStreamActions'
import { useAllTorexes } from './useAllTorexes'
import { adjustDecimals, getFlowRatePerSecond } from '@/utils'
import { useBenchmarkQuote } from './useBenchmarkQuote'
import { readContract, waitForTransactionReceipt } from 'viem/actions'
import { BLOCK_CONFIRMATIONS } from '@/constants'
import { analytics } from '@/utils/analytics'
import useSignFlowUpdate from './useSignFlowUpdate'
import { useTokenRelations } from './useTokenRelations'

interface DCAParams {
  flowRate?: FlowRate
  referrer?: Address
  amountToWrap?: bigint
}

interface UseTorexParams {
  user?: Address
  inboundToken?: Address
  outboundToken?: Address
}

const distributorFeeAddress = process.env.NEXT_PUBLIC_DISTRIBUTOR_FEE_ADDRESS

export const useTorex = ({
  user,
  inboundToken,
  outboundToken
}: UseTorexParams) => {
  const client = useClient()
  const userChainId = useChainId() as ChainId

  const { getTorexByTokens } = useAllTorexes()
  const { getUnderlyingTokenOf, isNativeAssetSuperToken } = useTokenRelations()

  const [torex, setTorex] = useState<Torex>()
  const [hasInverse, setHasInverse] = useState(false)
  const { flowExists } = useStreamActions()

  const [benchmarkQuote, durationSinceLastLME] = useBenchmarkQuote({
    torex: torex?.address
  })
  const [existingFlowRate, setExistingFlowrate] = useState(0n)
  const [isLoading, setIsLoading] = useState(false)

  const prevInboundToken = usePrevious(inboundToken)
  const prevOutboundToken = usePrevious(outboundToken)

  const { writeContractAsync } = useWriteContract()

  useEffect(() => {
    const effect = async () => {
      if (
        !inboundToken ||
        !outboundToken ||
        (inboundToken === prevInboundToken &&
          outboundToken === prevOutboundToken)
      )
        return

      const torex = getTorexByTokens(inboundToken, outboundToken)
      const inverseTorex = getTorexByTokens(outboundToken, inboundToken)

      if (!torex) return

      setTorex(torex)
      setHasInverse(Boolean(inverseTorex))
    }

    effect()
  }, [
    inboundToken,
    outboundToken,
    prevInboundToken,
    prevOutboundToken,
    getTorexByTokens
  ])

  useEffect(() => {
    const effect = async () => {
      if (!torex || !inboundToken) return

      const flowrate = await flowExists(inboundToken, torex.address)
      setExistingFlowrate(flowrate)
    }

    effect()
  }, [flowExists, inboundToken, torex])

  const signFlowUpdate = useSignFlowUpdate()

  const dca = useCallback(
    async ({
      flowRate,
      referrer = zeroAddress,
      amountToWrap = 0n
    }: DCAParams) => {
      let success = false

      if (!torex || !user || !client || !flowRate) {
        return { success }
      }

      setIsLoading(true)

      const fps = getFlowRatePerSecond(flowRate)

      // TODO: Retrieve them from the contract
      const supportedLanguages = ['en', 'hu', 'zh']

      let lang =
        navigator.languages.find((lang) =>
          supportedLanguages.includes(lang.split('-')[0])
        ) ?? 'en'

      lang = lang.split('-')[0]

      const LANG_32 = toHex(lang, { size: 32 })

      try {
        let adjustedWrapAmount: bigint
        // If the inbound token is the native asset, we don't need to adjust the decimals
        if (isNativeAssetSuperToken(torex.inboundToken.address)) {
          // TODO: Currently native assets are not supported by the macro. Modify this when they are.
          adjustedWrapAmount = 0n
        } else {
          const underlyingToken = getUnderlyingTokenOf(
            torex.inboundToken.address
          )

          adjustedWrapAmount = adjustDecimals(
            amountToWrap,
            underlyingToken?.decimals ?? 18,
            torex.inboundToken.decimals
          )
        }

        const [actionCode, message, actionParams] = await readContract(client, {
          address: sb712MacroAddress[userChainId],
          abi: sb712MacroABI,
          functionName: 'encode712SetDCAPosition',
          args: [
            LANG_32,
            {
              distributor: distributorFeeAddress,
              referrer,
              torex: torex.address,
              flowRate: fps,
              upgradeAmount: adjustedWrapAmount
            }
          ]
        })

        const { r, s, v } = await signFlowUpdate({
          message,
          torex: torex.address,
          flowRate: fps,
          distributor: distributorFeeAddress,
          referrer,
          upgradeAmount: adjustedWrapAmount
        })

        const signatureVRS = encodeAbiParameters(
          parseAbiParameters(['uint8, bytes32, bytes32']),
          [Number(v), r, s]
        )

        const params = encodeAbiParameters(
          parseAbiParameters(['uint8', 'bytes32', 'bytes, bytes']),
          [actionCode, LANG_32, actionParams, signatureVRS]
        )
        // (actionCode, lang, actionParams, signatureVRS) = abi.decode(batchParams, (uint8, bytes32, bytes, bytes));

        const hash = await writeContractAsync({
          address: macroForwarderAddress[userChainId],
          abi: macroForwarderABI,
          functionName: 'runMacro',
          args: [sb712MacroAddress[userChainId], params]
        })

        const rc = await waitForTransactionReceipt(client, {
          hash,
          confirmations: BLOCK_CONFIRMATIONS
        })

        analytics.track(
          existingFlowRate > 0 ? 'UPDATE_POSITION' : 'CREATE_POSITION',
          {
            torex: torex.address,
            user,
            flowRate: JSON.stringify(flowRate)
          }
        )

        success = rc.status === 'success'
      } catch (e) {
        if (!(e instanceof UserRejectedRequestError)) {
          console.error('Failed to initiate DCA.', e)
        }
      } finally {
        setIsLoading(false)
      }

      return { success }
    },
    [
      existingFlowRate,
      client,
      torex,
      userChainId,
      user,
      writeContractAsync,
      signFlowUpdate,
      getUnderlyingTokenOf,
      isNativeAssetSuperToken
    ]
  )

  return {
    dca,
    isLoading,
    existingFlowRate,
    torex,
    benchmarkQuote: formatEther(benchmarkQuote),
    durationSinceLastLME,
    hasInverse
  }
}
